html, body {
  background-color: white;
  color: black; /* Você também pode definir a cor do texto se necessário */
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
}

.logo {
  max-width: 150px;
  height: auto;
  margin: 0 auto;
}

.custom-carousel .carousel-item img {
  max-height: 300px; /* Ajuste a altura conforme necessário */
  object-fit: contain;
}

footer {
  background-color: #f8f9fa;
  text-align: center;
  width: 100%;
}

.top-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}
